@import '../partials/variables';

// create-id-email-layout page
.create-id-email-layout {
  p {
    &.withLink {
      justify-content: center;
      margin-bottom: 60px;
    }
  }
}

@include respond(phone) {
  .sign-in.layout-content {
    padding: 34px 32px;
  }

  // sign-in page
  .sign-in-layout {
    .title {
      margin-bottom: 22px;
    }
  }
}

@include respond(medium) {
  .sign-in.layout-content {
    padding: 50px 32px;
  }

  // sign-in page
  .sign-in-layout {
    .title {
      margin-bottom: 32px;
    }
  }
}

.continue {
  margin-top: 16px;
  margin-bottom: 24px;
}

// one-time-password page
.one-time-password-form {
  .text {
    font-size: 1.25rem;
  }

  .links-container {
    margin-top: 36px;

    div {
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .error-msg {
    font-size: 1rem;
    color: #667085;
  }

  .lds-spinner-container {
    margin-top: 40px;
  }
  &:not(.progress-bar) {
    .digit-textbox + .link-btn {
      margin-bottom: 4px;
    }
  }
}

.digit-textbox {
  display: flex;
  align-items: flex-start;

  &.error {
    input {
      caret-color: $red !important;
      border-color: $red !important;
    }
  }

  .digit-textbox-6 {
    input {
      width: 24px !important;
      margin-right: 26px;
    }
  }
}

.ReactModal__Content .modal-container .title,
.ReactModal__Content .modal-container .description {
  color: $text-grey;
  font-family: $font-family-work-sans;
  font-size: 1rem;
}

.ReactModal__Content .modal-container .title {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.04em;
}

.ReactModal__Content .modal-container button a {
  color: $white;
  text-decoration: none;
}

.error-msg {
  margin: 20px 0;
}
// verify id page
// reverify id page

.verify-id-layout,
.re-verify-id-layout {
  font-family: $font-family-work-sans;
  .icon-list-item:first-of-type {
    margin-top: 15px;
  }
  .button-container-2 {
    margin-top: 30px;
  }
}
.re-verify-id-layout {
  .icon-list-item:first-of-type {
    margin-top: 22px;
  }
}

.forgot-password-description {
  font-size: 1.25rem;
  margin-bottom: 32px;
}

// forgot password page
.forgot-password-form {
  margin-top: 20px;
  .notification {
    &.error {
      + .textbox-form {
        margin-top: 0;
      }
    }
  }
  .primary-btn {
    + .text {
      margin-top: 24px;
    }
  }
}

// reset password success page
.reset-password-success-layout {
  img {
    + h1 {
      margin-bottom: 0;
    }
  }
  .text {
    margin-bottom: 40px;
    font-size: 1.25rem;
  }
}

.reset-password-success-check {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  img {
    width: 40px;
    object-fit: contain;
  }
}

// verify health card page
// verify driver licence card page
// verify voluntery page
.verify-health-card,
.verify-driver-licence-card,
.verify-voluntary-card {
  p {
    + .notification {
      margin-top: 24px;
    }
  }
}

// verify health card success page
// re-verify health card success page
.verify-health-card-success,
.re-verify-health-card-success {
  h1 {
    + p {
      margin-bottom: 32px;
    }
  }
  .icon-list-item {
    + .primary-btn {
      margin-top: 33px;
      + .primary-btn {
        margin-top: 16px;
      }
    }
  }
  .primary-btn {
    min-width: 334px;
    @include respond(phone) {
      min-width: -webkit-fill-available;
    }
  }
}
.re-verify-health-card-success {
  h1 {
    + p {
      margin-bottom: 0;
    }
  }
  .icon-list-item {
    &.complete {
      margin-top: 22px;
    }
  }
}

// verify success page
.verify-success {
  margin-top: 20px;
  img {
    width: 78px;
    text-align: center;
    margin: 0 auto;
  }
  h1 {
    margin-top: 40px;
    margin-bottom: 24px;
  }
  .heading {
    margin-bottom: 13px;
  }
  .primary-btn {
    margin-top: 40px;
  }
}

// identity verification
.identity-verification {
  .notification {
    padding: 17px;
    margin-bottom: 32px;
    p {
      &:first-of-type {
        margin-top: 2px;
      }
      + p {
        margin-top: 5px;
        margin-bottom: 4px;
      }
    }
    button {
      margin-top: 16px;
    }
  }
}

// identity verifiecation verified
.identity-verification-verified {
  .display-saved-info {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 16px;
    .info-box {
      h3 {
        font-weight: $bold !important;
      }
    }
    @include respond(phone) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }
  }
}

.verify-hc-page {
  padding-bottom: 60px !important;
}

.verify-hc-success {
  .icon-list-item:first-of-type {
    color: #646464;
    margin-top: 15px;
  }

  .button-container {
    display: inline-flex;
    flex-direction: column;
    margin-top: 40px;
    width: 400px;

    button {
      width: 100%;

      &:last-of-type {
        margin-top: 16px;
      }
    }
  }
}

.verification-success-page {
  margin-top: 40px !important;

  .verfiy-icon-container {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-container-2 {
    margin-top: 40px;
  }

  .description1 {
    font-size: 1.375rem;
  }
}

.reverify-id-portal {
  .notification {
    padding: 17px;
    align-items: center;

    > i {
      margin-right: 16px;
      align-items: center;
      display: flex;
    }

    > strong {
      margin-right: 6px;
    }
  }

  .sub-title {
    margin-top: 32px;
  }

  .box-button {
    > button {
      font-size: 0.875rem;
      font-weight: 700;
    }

    &.row {
      margin-top: 8px;
    }

    &:last-of-type {
      border: none;
    }
  }
}

.level-1-verification {
  background: #f2f2f2;
  border-radius: 6px;
  padding: 20px;
  display: flex;

  > i {
    margin-right: 12px;
  }

  .text-section {
    margin-top: 3px;
    display: flex;
    flex-direction: column;

    > p {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    > button {
      margin-top: 16px;
    }
  }
}

.identity-profile-information {
  h1 {
    margin-bottom: 42px !important;
  }

  .sub-header-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    > h3 {
      font-size: 1.5rem;
      color: $red;
      margin-right: 12px;
      font-family: $font-family-georgia !important;
    }

    .edit-btn {
      text-decoration: none;
    }
  }

  .sub-title-description {
    margin-top: 20px;
  }

  .details-container {
    margin: 32px 0;
    display: flex;
    flex-wrap: wrap;

    .item {
      flex: 50%;
      margin-bottom: 20px;

      @include respond(phone) {
        flex: 100%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        font-weight: 700;
        font-size: 1rem;
      }

      .detail {
        margin-top: 6px;
        color: #333333;
      }
    }
  }
}

.error-container {
  margin-top: 24px;
}

.lead-text {
  font-size: 1.25rem;
  letter-spacing: -0.0125rem;
  line-height: 1.4;
  margin-bottom: 32px;
}

.session-modal {
  color: $text-grey;
  .title {
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    text-align: left;
    margin-bottom: 24px;
  }

  .btn-sign {
    min-width: 135px;
  }

  .btn-exit {
    min-width: 109px;
    margin-right: 24px !important;
  }

  .btn-continue {
    min-width: 154px !important;
  }
}

.wallet-decline-modal {
  .title {
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    text-align: left;
    margin-bottom: 24px;
  }

  .decline-btn {
    display: flex;

    & > button {
      margin-right: 24px;
    }

    .go-back {
      min-width: 126px;
      @include respond(medium) {
        min-width: 148px;
      }

      .decline {
        min-width: 83px;
      }
    }
  }
}

.wallet-scopes-modal {
  color: $text-grey;

  .container {
    margin-bottom: 32px;
  }

  .title {
    font-family: $font-family-work-sans;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    text-align: left;
    margin-bottom: 24px;
  }

  .scope-section {
    margin: 16px 0px;
  }
  .scope {
    font-family: $font-family-work-sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 10px;
  }
}
