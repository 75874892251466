@import '../variables';
.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.4) !important;
}

.ReactModal__Body--open {
  position: inherit;
}

.ReactModal__Content--after-open.modal-content {
  inset: 50% auto auto 50%;
  border: 0;
  border-radius: 4px;
  bottom: auto;
  min-height: 10rem;
  position: fixed;
  right: auto;
  transform: translate(-50%, -50%);
  min-width: 20rem;
  width: 80%;
  overflow: hidden;
  padding: 0;
  max-height: 80vh;
  max-width: 600px;
  min-height: auto;
  min-height: auto;
  background: white;
  .primary-btn {
    margin-top: 24px;
    // min-width: 100%;
    @include respond(medium) {
      min-width: 316px;
    }
  }
  h1 {
    font-size: 1.875rem;
  }
  button {
    &.primary-btn {
      width: 100%;
    }
  }
  .account-settings-login-modal {
    text-align: center;
    h1 {
      margin-top: 16px;
    }
    .link-btn {
      margin-top: 23px;
      font-weight: 400;
    }
  }
  .review-account-modal {
    text-align: center;
    h1 {
      font-family: $font-family-lato;
      font-weight: 700;
    }
    p {
      font-family: $font-family-open-sans;
      font-weight: 400;
    }
    button {
      p {
        font-family: $font-family-open-sans;
      }
    }
  }
  .ids-dl-modal,
  .ids-opc-modal,
  .ids-hcv-modal {
    text-align: center;
    h1 {
      font-family: $font-family-lato;
      font-weight: 700 !important;
      strong {
        font-weight: 700;
      }
    }
    p {
      font-family: $font-family-open-sans;
      color: $black-light;
      strong {
        font-weight: 700;
        color: $black;
      }
    }
    img {
      margin-bottom: 21px;
      display: initial;
    }
  }
  .ids-opc-modal {
    img {
      margin: 0 auto 20px auto;
    }

    .primary-btn {
      font-size: 1rem;
    }
  }
  .login-modal {
    .link-btn {
      margin-top: 30px;
    }
    .error-email {
      font-weight: 700;
    }
  }
  .choose-id-modal,
  .login-account-create-error,
  .login-account-create-support {
    font-family: $font-family-open-sans;
    h1 {
      font-family: $font-family-lato;
      font-size: 1.5rem;
      font-weight: 700 !important;
      text-align: center;
    }
  }

  .consent-decline-modal {
    > h1 {
      margin-top: 16px;
      text-align: center;
      line-height: 1.2;
      font-weight: 700;
    }

    > p {
      color: #232323;
      font-size: 1rem;
      font-weight: 300;
    }

    .primary-btn {
      margin-top: 24px;
      font-size: 1rem;
      font-weight: 400;
      margin: 0 auto;
      margin-top: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    .link-btn {
      text-align: center;
      padding: 14px 0;
      text-decoration: underline;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &.account-deleted {
      svg {
        display: flex;
        width: 100%;
      }

      > h1 {
        margin-top: 24px;
        font-size: 1.5rem;
      }

      > p {
        text-align: center;
      }
    }

    &.error {
      > h1 {
        margin-top: 0;
      }

      > p {
        text-align: center;
      }
    }
  }
  .login-account-create-error,
  .login-account-create-support {
    text-align: center;
    .primary-btn {
      margin-bottom: 27px;
      margin-top: 32px;
    }
    .link-btn {
      text-align: center;
      text-decoration: underline;
      width: 100%;
      margin-bottom: 11px;
    }
  }
  .login-account-create-support {
    .primary-btn {
      margin-top: 24px;
    }
  }
  > div {
    padding: 48px;
    @include respond(phone) {
      padding: 24px;
    }
  }
  .modal-header {
    text-align: right;
    margin-bottom: 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    padding: 0 !important;
    + div {
      padding: 0 40px 40px 40px !important;
    }
    @include respond(phone) {
      + div {
        padding: 0 24px 24px 24px !important;
      }
    }
  }
  .btn-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 32px;
  }
  @include respond(medium) {
    &.ReactModal__Content--after-open {
      .primary-btn {
        width: fit-content;
        min-width: 107px;
        text-align: center;
      }
    }
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: calc(80vh - 54px - 24px);
    max-width: 100%;
    overflow: auto;
    h2 {
      color: $text-grey;
      font-size: 1.75rem;
      font-family: $font-family-work-sans;
      margin-bottom: 24px;
      font-weight: 500;
      line-height: 30.8px;
      letter-spacing: -0.04em;
      
    }
    .icon-list-item {
      margin-bottom: 16px;
      i {
        padding-right: 12px;
      }
    }
    img + h2 {
      margin-top: 24px;
    }
    a:not(.primary-btn) {
      color: $primary;
      text-decoration: underline;
      font-weight: $bold;
      display: initial;
    }

    &.isLoading {
      padding-top: 20px;
    }
    .left-align {
      text-align: left !important;
      display: inline-block;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      > a {
        margin-left: 2px;
        display: inline-block;
      }
    }

    .margin-bottom {
      margin-bottom: 12px;
    }
  }
  .cant-access-app-modal {
    letter-spacing: -0.01rem;
    h2 {
      font-family: $font-family-work-sans;
      font-size: 1.75rem;
      line-height: 1;
      font-weight: 500;
      letter-spacing: -0.07rem;
      margin-bottom: 16px;
      color: $text-grey;
    }
    .primary-btn {
      margin-top: 32px;
      letter-spacing: -0.01rem;
      min-width: unset;
    }
    a:not(.primary-btn) {
      font-weight: normal;
    }
  }
}

// OTP Modal
.otp-modal {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.otp-modal-title {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 30.8px;
  letter-spacing: -0.04em;

}

.otp-modal-desc {
  margin-top: 24px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.01em;
}