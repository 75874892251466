form {
  flex-direction: column;
  display: flex;
  .textbox-form {
    + .text {
      margin-bottom: 16px;
      margin-top: 16px;
      &.error {
        margin-bottom: 0;
      }
      + button {
        margin-top: 0;
      }
    }
    + button {
      margin-top: 24px;
    }
  }
  .notification {
    &.error {
      // margin-bottom: 24px;
    }
  }
  .textbox-form-input-password {
    + ol {
      margin-top: 16px;
    }
  }
  + .link-btn {
    margin-top: 30px !important;
    margin-bottom: 0 !important;
  }
  .textbox-form-container-2 {
    .textbox-form {
      margin-top: 0;
      @include respond(phone) {
        &:nth-child(2) {
          margin-top: 20px;
        }
      }
    }
  }
  &.sign-in-form {
    font-family: $font-family-work-sans;
    .error-msg {
      color: #667085;
      margin-top: 8px;
    }
    .textbox-form {
      max-width: 556px;
      width: 100%;
      margin-top: 24px;

      &:first-of-type {
        margin-top: 0;
      }

      + .link-btn {
        margin-top: 20px;
        font-weight: 400;
      }

      input {
        background: #fff;
      }

      .textbox-form-input.error {
        input {
          border-width: 3px;
        }
      }
    }
    .link-btn {
      font-size: 1rem;
      line-height: 1.4;
      min-height: unset;
      + button {
        margin-top: 24px;
        + p {
          display: flex;
          margin-top: 24px;
          font-weight: 500;
          font-family: $font-family-work-sans;
          font-size: 1.25rem;
        }
      }
    }
    a.primary-btn {
      margin-top: 20px;
      color: $primary;
      font-family: $font-family-work-sans;
      font-size: 1.125rem;
    }
    & + .post-sign-in-form {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      margin-top: 32px;
      gap: 32px;
      @include respond(large) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .sign-up-redirect {
        font-family: $font-family-work-sans;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 28px 24px;
        border: 4px solid $green-light;
        border-radius: 4px;
        h2 {
          font-size: 1.5625rem;
          line-height: 1.1;
          font-weight: 500;
          letter-spacing: -0.063rem;
          color: #006d57;
        }
        p {
          font-size: 1rem;
          line-height: 1.4;
          letter-spacing: -0.01rem;
        }
        button {
          margin-top: 8px;
        }
      }
      .access-form {
        margin-top: 0;
        .verif-code {
          @include respond(large) {
            display: flex;
            flex-direction: row;
            gap: 32px;
          }
          .verif-code-input-container {
            flex-grow: 1;
            margin-bottom: 0;
            @include respond(phone) {
              margin-bottom: 16px;
            }
          }
          .textbox-form {
            margin-top: 16px;
          }

          @include respond(medium) {
            button {
              margin-top: 11px;
            }
          }

          @include respond(large) {
            button {
              margin-top: 46.4px;
            }
          }

          button {
            height: fit-content;
            padding: 12px 22px;
          }
        }
      }
    }
  }
  &.create-id-email-form {
    @include respond(medium) {
      margin-top: 36px;
    }

    @include respond(phone) {
      margin-top: 30px;

      .continue {
        margin-top: 18px;
      }
    }

    .input-container {
      padding-bottom: 22px;
    }
  }
  &.health-card-form,
  &.driver-licence-form,
  &.voluntary-form {
    margin-top: 24px;
    .primary-btn {
      margin-top: 20px !important;
    }
    .text {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }
  &.terms-of-service-form {
    .terms-of-service-description-container {
      margin-bottom: 26px;
      h2 {
        font-family: $font-family-work-sans;
        color: $text-grey;
        font-size: 2.25rem;
        margin-bottom: 12px;
        @include respond(medium) {
          font-size: 2.875rem;
        }
      }
      p {
        margin-bottom: 8px;
        max-width: 860px;
        color: $text-grey;
        a {
          display: inline;
          color: $primary;
          text-decoration: underline;
          font-weight: 400;
        }
      }
      + .terms-of-service-checkbox-container {
        color: $text-grey;
        margin-top: 0px;
      }
    }
    .terms-of-service-checkbox-container {
      margin-bottom: 25px;
      color: $text-grey;
    }
    .primary-btn {
      margin-top: 24px;
    }
  }
}

.phone-form {
  margin-top: 22px;
  .textbox-form {
    label {
      font-weight: 700;
    }

    .textbox-form-input input {
      border: 1px solid $gray-light-3;
      max-width: 556px;
      border-radius: 8px;
    }
  }
  .textbox-form .textbox-form-input:not(.textbox-form-input-info) input {
    padding: 12px;
    border-color: $text-grey;
  }
}

.textbox-form-input-error-alert {
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  border: 4px solid $input-error-border !important;
}

.textbox-form-input-error-text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.01em;
  color: $gray-dark-3;

  margin-top: 8px;
}
