@import '../variables';
.notification {
  display: block;
  background-color: $gray-light-4;
  padding: 30px 24px;
  border-radius: 4px;
  color: $black-light;
  &.icon {
    display: flex;
  }
  i {
    margin-right: 21px;
  }
  p {
    font-weight: 400;
  }
  &.error {
    margin-bottom: 24px;
    background-color: $error-background;
    padding: 20px;
    color: $text-grey;
    font-family: $font-family-work-sans;
    p {
      a {
        display: inline;
        text-decoration: underline;
        margin: 0;
      }
    }
    + .textbox-form {
      margin-top: 0;
    }
  }
  &.center {
    align-items: center;
    svg {
      display: block;
    }
  }
  button {
    &.link-btn {
      width: 100%;
      span {
        font-weight: 600;
      }
    }
  }
  a {
    color: $primary;
    font-weight: 700;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
  }
  .center {
    text-align: center;
  }
}
